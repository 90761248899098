import bfa from '@buzzfeed/bf-analytics';
import permutiveService from '@buzzfeed/buzzblocks/js/services/permutive';
import util from '@buzzfeed/buzzblocks/js/services/util/standalone.js';

import facebook from '@buzzfeed/bf-analytics/lib/modules/facebook';
import facebookMapping from './common/facebookMapping';
import permutive from '@buzzfeed/bf-analytics/lib/modules/permutive';
import pixiedust from '@buzzfeed/bf-analytics/lib/modules/pixiedust';

import domi from '@buzzfeed/bf-analytics/lib/plugins/domi';
import logger from '@buzzfeed/bf-analytics/lib/plugins/logger';
import mapper from '@buzzfeed/bf-analytics/lib/plugins/mapper';
import pageInfo from '@buzzfeed/bf-analytics/lib/plugins/pageInfo';
import userInfo from '@buzzfeed/bf-analytics/lib/plugins/userInfo';
import testMode from '@buzzfeed/bf-analytics/lib/plugins/testMode';
import dataBinder from '@buzzfeed/bf-analytics/lib/plugins/dataBinder';
import debugTracker from '@buzzfeed/bf-analytics/lib/plugins/debugTracker';
import trackingUtils from '@buzzfeed/bf-analytics/lib/plugins/trackingUtils';
import generalSettings from '@buzzfeed/bf-analytics/lib/plugins/generalSettings';
import globalBfaEndpoint from '@buzzfeed/bf-analytics/lib/plugins/globalBfaEndpoint';

import pixiedustMapping from './common/pixiedustMapping';
import {
  sendAddressability,
  sendPageview,
  initClientEventTracking,
  initTimeSpentTracking,
  sendWebPerformancePageview,
  sendABTestEvent,
} from './common/client-event-tracking';

(function () {
  'use strict';

  const queryParams = util.getQueryParams();

  var bfaCore = new bfa.Core();

  var bzfdContext = window.BZFD.Context;
  var bzfdConfig = window.BZFD.Config;
  const hasDebugFlag = queryParams.bfadebug === 'true';

  if (typeof bzfdContext === 'undefined' || bzfdConfig === 'undefined') {
    return;
  }

  // ==================================================== Initial Objects

  var settings = {
    mode: hasDebugFlag || bzfdConfig.env === 'dev' ? bfaCore.defs.bfaMode.develop : bfaCore.defs.bfaMode.prod,
    webRoot: bzfdConfig.webRoot,
    staticRoot: bzfdConfig.staticRoot,
    env: bzfdConfig.env,
    source: 'news_web',
    logLevel: hasDebugFlag ? 3 : 1,
    debug: hasDebugFlag,
    isConsentRequired: queryParams.gdpr === 'quantcast' || bzfdConfig.isConsentRequired,
  };

  var page = {
    country: bzfdContext.page.localization.country,
    language: bzfdContext.page.localization.language,
    name: bzfdContext.page.name,
    type: 'feed',
    platform: 'news_feedpager',
    uri: {
      cleanedPath: document.location.pathname,
    },
    section: bzfdContext.page.section,
  };

  var userData = {
    referrer: document.referrer,
  };

  // ==================================================== Adding plugins

  bfaCore
    .plugin(generalSettings, settings)
    .plugin(logger)
    .plugin(domi)
    .plugin(globalBfaEndpoint)
    .plugin(mapper)
    .plugin(pageInfo)
    .plugin(userInfo)
    .plugin(trackingUtils)
    .plugin(testMode)
    .plugin(debugTracker)
    .plugin(dataBinder, {
      bfaFunctionName: 'bfa',
      generalBinder: {
        pageExitBinder: true,
      },
      domBinder: {
        delegatedMapping: {
          click: [{
            event: 'click',
            selector: 'a',
            options: {
              handlers: ['ATagWithExternalLinkHandler', 'ATagWithInternalLinkHandler', 'ATagDefaultHandler', ],
            },
          }, {
            event: 'click',
            selector: 'input',
          }, {
            event: 'click',
            selector: 'button',
          }]
        },
      }
    });

  // ==================================================== Adding modules

  // Register which modules you want BFA to use here.
  // Look to other xpagers for reference on the correct configuration,
  // you will need to provide mappings


  bfaCore
    .registerModule(pixiedust, {
      source: 'news_web',
      configMapsDelegate: pixiedustMapping,
      samplingRate: bzfdConfig.tracking.pixiedust.sampling_rate,
      impressionsSamplingRate: bzfdConfig.tracking.pixiedust.impressions_sampling_rate,
      scrollDepthSamplingRate: bzfdConfig.tracking.pixiedust.scroll_depth_sampling_rate,
      instrumentationSamplingRate: bzfdConfig.tracking.pixiedust.instrumentation_sampling_rate,
    })
    .registerModule(facebook, {
      extFilesPaths: [ 'https://connect.facebook.net/en_US/fbevents.js', ],
      configMapsDelegate: facebookMapping,
    })
    .registerModule(permutive, {
      enabled: permutiveService.checkIsOn()
    });

  // ==================================================== Initialization workflow

  bfaCore.activatePlugins();
  bfaCore.push('general/page/update', page);
  bfaCore.push('general/user/update', userData);
  bfaCore.domi.onDomReady(function () {
    bfaCore.push('plugin/binder/activate');
  });

  bfaCore.activate();
  initClientEventTracking();
  sendWebPerformancePageview();
  sendABTestEvent();
  sendAddressability();
  sendPageview();
  initTimeSpentTracking();
})();
