/* eslint-disable consistent-return */
import { get } from 'lodash';
import { map as metricsMapping } from '@buzzfeed/buzzblocks/js/services/metrics/mapping';
import { pixiedustMap as videoPlayerMapping } from '@buzzfeed/buzzblocks/js/services/video-player-analytics/bfa-pixiedust';
import solid from '@buzzfeed/buzzblocks/js/services/solid';

export default function(mapper) {
  let mode = solid.isXsmall() || solid.isSmall() ? 'mobile' : 'desktop';

  let commonFields = mapper.createMapTemplate('commonFields');
  commonFields.addMapTo('referrer', '').mapFrom('data.user.referrer');
  commonFields.addMapTo('url', '').mapFrom('data.page.uri.href');
  commonFields.addMapTo('source', 'news_web');
  commonFields.addMapTo('platform', 'news_feedpager');
  commonFields.addMapTo('mode', mode);
  commonFields.addMapTo('page_type', 'feed');
  commonFields.addMapTo('page_url', window.location.href.split('?')[0]);
  commonFields.addMapTo('page_edition', get(window.BZFD.Context, 'page.localization.country'));

  let pageExitMap = mapper.getOrCreateMap('track/page/exit');
  pageExitMap.applyTemplateByName('commonFields');
  pageExitMap.addMapTo('type', 'page_exit');

  // Adds mapping for client side metrics reporting
  metricsMapping(mapper);
  videoPlayerMapping(mapper);
}

/* eslint-enable consistent-return */
