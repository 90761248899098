import { CONTEXT_PAGE_TYPES, CONTEXT_PAGE_IDS_NEWS } from '@buzzfeed/client-event-tracking/dist/lib/constants';
import { createClientEvent } from '@buzzfeed/client-event-tracking';
import {
  ab_test,
  addressability,
  pageview,
  time_spent,
  external_link,
  internal_link,
  impression,
  content_action,
} from '@buzzfeed/client-event-tracking/dist/lib/events';
import {
  createClientClickHandler,
  createClientImpressionHandler,
  createClientTimeSpentHandler,
} from '@buzzfeed/client-event-tracking/dist/lib/handlers';
import abeagle from '@buzzfeed/buzzblocks/js/services/abeagle';
import { getExperimentId } from '@buzzfeed/buzzblocks/js/services/abeagle/standalone';
import cookies from '@buzzfeed/buzzblocks/js/services/cookies';
import storage from '@buzzfeed/bf-utils/lib/storage';
import { urlQueryToObject } from '@buzzfeed/bf-utils/lib/query-string';
import { trackPerformance } from '@buzzfeed/performance';

const getContextPageId = (pageName) => {
  switch (pageName) {
  case 'user':
    return BZFD.Context.page.username;
  case 'fincen-files':
    return CONTEXT_PAGE_IDS_NEWS.collection;
  default:
    return CONTEXT_PAGE_IDS_NEWS[pageName];
  }
};

const webCommonLayerData = () => ({
  context_page_id: String(getContextPageId(BZFD.Context.page.context_page_name)),
  context_page_type: BZFD.Context.page.context_page_name === 'user' ? 'user' : CONTEXT_PAGE_TYPES.FEED,
  destination: 'buzzfeed_news',
  page_edition: BZFD.Context.page.localization.country,
});
const sendClientEvent = createClientEvent({ env: BZFD.Config.env, source: 'web_news' });


const createClientEventWebData = (event_config) =>
  (...layers) => {
    sendClientEvent(event_config, webCommonLayerData(), ...layers);
  };

export const initClientEventTracking = () => {
  /**
   * Allows other apps on the page, like `bf_header_ui`, to get context layer data
   */
  window.clientEventTracking = {
    getPageContextLayer() {
      return webCommonLayerData;
    },
    env: BZFD.Config.env,
  };
};

const trackClientTimeSpent = createClientEventWebData(time_spent);
const trackClientExperimentActive = createClientEventWebData(ab_test);
const trackClientContentAction = createClientEventWebData(content_action);
const trackClientInternalLink = createClientEventWebData(internal_link);
const trackClientExternalLink = createClientEventWebData(external_link);
const trackClientImpression = createClientEventWebData(impression);

const attachClientImpressionHandler = createClientImpressionHandler(trackClientImpression);
const attachClientInternalLinkHandler = createClientClickHandler(trackClientInternalLink);
const attachClientExternalLinkHandler = createClientClickHandler(trackClientExternalLink);
const attachClientContentActionHandler = createClientClickHandler(trackClientContentAction);
const attachClientTimeSpentHandler = createClientTimeSpentHandler(
  ({ layers }, ...additional_args) => {
    trackClientTimeSpent(...additional_args, ...layers, { referrer_uri: document.referrer });
  }
);

export const initTimeSpentTracking = async () => {
  const variant = await abeagle.getExperimentVariant('TimeSpentRO_3', { rejectErrors: false });
  if (variant === 'on') {
    attachClientTimeSpentHandler({});
  }
};

export const sendAddressability = () => {
  const hem = cookies.get('hem');
  const user = cookies.get('bf2-b_info');
  const queryObject = urlQueryToObject(window.location.search);

  let addressableSource = [];
  if (queryObject['email_hash'] || storage.sessionGet('newsletterAddressable')) {
    storage.sessionSet({ key: 'newsletterAddressable', value: 'true' });
    addressableSource.push('newsletter');
  }
  if (user) {
    addressableSource.push('auth');
  }

  sendClientEvent(addressability, webCommonLayerData, {
    is_addressable: !!hem,
    addressable_source: addressableSource,
    addressable_partner: []
  });
};

export const sendPageview = () => {
  sendClientEvent(pageview, webCommonLayerData());
};

export const sendWebPerformancePageview = () => {
  const sendEvent = ({ flush, sample_rate, type }, ...layers) => {
    if (window.location.search.includes('e2e_test') || Math.random() <= sample_rate) {
      sendClientEvent({ flush, sample_rate, type }, ...layers);
    }
  };
  trackPerformance(sendEvent, {
    layers: [ webCommonLayerData ],
    sample_rate: 0.5
  });
};

export const sendABTestEvent = async() => {
  let experiment_id = await getExperimentId();
  if (BZFD.Context.page.name === 'home') {
    const experimentCookie = 'dsl25_frhf';
    const trackingCookie = `${experimentCookie}_tracked`;
    const variantExperimentCookie = cookies.get(experimentCookie);
    const variantTrackingCookie = cookies.get(trackingCookie);
    if(variantExperimentCookie) {
      experiment_id.push(`${experimentCookie}|${experimentCookie}|1|${variantExperimentCookie}|1`);
    }
    if(variantTrackingCookie) {
      experiment_id.push(`${trackingCookie}|${experimentCookie}|1|1|1`);
    }
  }

  trackClientExperimentActive({}, { experiment_id });
};

/**
 * Binds unit tracking to all `data-pixiedust` elements inside the specified root
 * @param {HTMLElement}
 * @return {void}
 */
export const bindPixiedustUnitTracking = singleItem => {
  if (!singleItem) {
    return;
  }

  singleItem.querySelectorAll('[data-pixiedust]').forEach(element => {

    if(!element.getAttribute('data-pixiedust').length) {
      return;
    }

    const itemData = JSON.parse(decodeURIComponent(element.getAttribute('data-pixiedust')));

    if(itemData.target_content_url) {
      attachClientExternalLinkHandler(element, itemData, itemData);
      if (itemData.hasImpression === true) {
        attachClientImpressionHandler(element, itemData, itemData);
      }
      return;
    }

    if(itemData.action_type && itemData.action_value) {
      attachClientContentActionHandler(element, itemData, itemData);
      return;
    }

    if(itemData.internal_only) {
      attachClientInternalLinkHandler(element, itemData, itemData);
      return;
    }

    attachClientImpressionHandler(element, itemData, itemData);
    attachClientInternalLinkHandler(element, itemData, itemData);
  });
};
